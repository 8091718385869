<template>
  <v-dialog
    v-model="dialog"
    max-width="950"
    :persistent="load"
    scrollable
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ nuevo ? 'Nuevo cliente mayorista' : `Cliente mayorista ${p_gestor.codigo} - ${p_gestor.nombre}` }}
        <v-spacer></v-spacer>
        <template v-if="!nuevo && p_editar">
          <v-switch
            v-model="editar"
            label="Editar"
            class="d-flex align-top mt-0"
            hide-details
            dense
          ></v-switch>
          <v-divider
            class="mx-3"
            vertical
          ></v-divider>
        </template>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="4" md="5" class="py-0" :class="nuevo ? 'd-flex' : ''">
              <template v-if="nuevo">
                <v-text-field
                  v-model.trim="apellido"
                  label="Apellido"
                  class="mr-2"
                  :rules="editar ? [rules.required] : []"
                  validate-on-blur
                  autofocus
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                  v-model.trim="nombre"
                  label="Nombre"
                  class="ml-2"
                  :rules="editar ? [rules.required] : []"
                  validate-on-blur
                  outlined
                  dense
                ></v-text-field>
              </template>
              <v-text-field
                v-else
                v-model.trim="gestor.nombre"
                label="Apellido y Nombre"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="2" class="py-0">
              <v-text-field
                v-model="gestor.num_doc"
                label="Nº Documento"
                type="number"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="codigo_vincular = null"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="2" class="py-0">
              <v-autocomplete
                v-model="gestor.tipo_doc_codigo"
                label="Tipo documento"
                item-text="nombre"
                item-value="id"
                :items="tipos_doc"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="gestor.cond_iva_codigo"
                label="Condición IVA"
                item-text="condicion_iva_nombre"
                item-value="condicion_iva_codigo"
                :items="cond_iva"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model="gestor.domicilio"
                label="Domicilio"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="selectedProvincia"
                label="Provincia"
                item-text="provincia_nombre"
                :items="provincias"
                :readonly="!editar"
                :filled="!editar"
                return-object
                outlined
                dense
                @change="delete gestor.localidad_codigo; getLocalidades()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="4" md="5" class="py-0">
              <v-autocomplete
                v-model="gestor.localidad_codigo"
                label="Localidad"
                item-text="localidad_nombre"
                item-value="localidad_codigo"
                :items="localidades"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" class="py-0">
              <v-text-field
                v-model="gestor.email"
                label="Email"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="2" class="py-0">
              <v-text-field
                v-model="gestor.telefono"
                label="Telefono"
                :readonly="!editar"
                :filled="!editar"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="gestor.sucursal_codigo"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                :items="sucursales"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
                @change="delete gestor.ejecutivo_codigo"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="3" class="py-0">
              <v-autocomplete
                v-model="gestor.ejecutivo_codigo"
                label="Ejecutivo"
                item-text="nombre"
                item-value="codigo"
                :items="ejecutivos.filter(ej => (ej.sucursal == gestor.sucursal_codigo && ej.inhabilitado != 1) || ej.codigo == gestor.ejecutivo_codigo)"
                :rules="editar ? [rules.required] : []"
                :readonly="!editar"
                :filled="!editar"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- Entidades Depósito -->
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  Entidades Depósito
                </v-card-title>
                <v-card-text>
                  <!-- Agregar una nueva entidad -->
                  <v-row v-if="permiso == 1 && editar == true">
                    <v-col cols="12" sm="3" md="3" class="py-2">
                      <v-autocomplete
                        v-model="selectedEntidad"
                        item-text="entidad_nombre"
                        :items="entidadesDeposito"
                        hide-details
                        outlined
                        dense
                        return-object
                        label="Entidad"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-2">
                      <v-btn color="info" @click.stop="addEntidadDeposito()">Agregar</v-btn>
                    </v-col>
                  </v-row>
                  <!-- Listado de Entidades -->
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-data-table
                        class="my-2"
                        :headers="headersEnt"
                        :items="entidades"
                        :loading="loadEnt"
                        :search="searchEnt"
                        :items-per-page="-1"
                        hide-default-footer
                        dense
                      >
                        <!-- Msj que se mostrara si no existen resultados -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            No hay Entidades para mostrar.
                          </v-alert>
                        </template>
                        <!-- Acciones -->
                        <template v-slot:[`item.acciones`]="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn small icon color="error" @click="quitarEntidad(item)" v-on="on" :disabled="permiso == 0 || editar == false">
                                <v-icon small>far fa-times-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Quitar Entidad</span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="item.origen_id == 8 && (nuevo || !gestor.usuario)"
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                :color="credenciales.user && credenciales.pass ? 'indigo' : 'success'"
                                :disabled="!editar"
                                small
                                icon
                                @click="dialog_usuario = true"
                              >
                                <v-icon small>fas fa-user{{credenciales.user && credenciales.pass ? '-edit' : '-plus'}}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ credenciales.user && credenciales.pass ? 'Editar' : 'Crear' }} usuario</span>
                          </v-tooltip>
                          <v-tooltip
                            v-if="item.origen_id == 8 && credenciales.user && credenciales.pass"
                            bottom
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                color="deep-orange"
                                small
                                icon
                                @click="credenciales = { user: '', pass: '' }"
                              >
                                <v-icon small>fas fa-user-minus</v-icon>
                              </v-btn>
                            </template>
                            <span>No crear usuario</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                      <v-alert
                        v-if="credenciales.user && credenciales.pass"
                        type="info"
                        border="left"
                      >
                        Al guardar los cambios se dará de alta al usuario {{ credenciales.user.toUpperCase() }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <!-- dialogo para cuando encuentra una coincidencia y la va a vincular -->
        <v-alert
          v-if="codigo_vincular != null"
          class="mx-auto mt-4 mb-0"
          max-width="700"
          type="info"
          border="left"
          outlined
          text
        >
          El cliente mayorista se vinculará al cliente {{ nombre_vincular }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          class="mr-2"
          :color="editar ? 'error' : 'secondary'"
          :disabled="load"
          @click="dialog = false"
        >
          {{ editar ? 'Cancelar' : 'Volver' }}
        </v-btn>
        <BtnConfirmar
          v-if="editar"
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
    <!-- COMPONENTES -->
    <!-- modal de coincidencias -->
    <v-dialog
      v-model="modal"
      max-width="800"
      persistent
    >
      <v-card>
        <v-card-title class="pb-0">
          Se encontraron las siguientes coincidencias con clientes minoristas
        </v-card-title>
        <v-card-title class="pt-1 body-1">
          Seleccione el cliente que desee vincular
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <v-data-table
            class="cebra"
            sort-by="nombre"
            :headers="headers"
            :items="coincidencias"
            :items-per-page="-1"
            hide-default-footer
            dense
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon color="success" @click="seleccionar(item)" v-on="on">
                    <v-icon small>fas fa-check</v-icon>
                  </v-btn>
                </template>
                <span>Seleccionar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex justify-end py-4">
          <v-btn
            color="error"
            @click="modal = false"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CrearUsuarioTmp
      v-model="dialog_usuario"
      :credenciales="credenciales"
      :nombres="p_gestor ? p_gestor.nombre : null"
      :apellido="apellido"
      :nombre="nombre"
      @updateCredentials="setUser"
    />
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear un gestor
 * 
 *  Notas: este componente emite acciones (@nuevo, @editar) al padre caundo se crea o edita un gestor (en caso de estar definidas)
 */
import { mapState } from 'vuex'
import { Encrypt } from '../../../util/aes'
import { get_nombre_obj_arr } from '../../../util/utils'
import BtnConfirmar from '../../util/BtnConfirmar'
import CrearUsuarioTmp from '../../util/CrearUsuarioTmp.vue'

export default {
  data () {
    return {
      load: false,
      modal: false,
      nuevo: false,
      editar: false,
      nombre: null,
      apellido: null,
      dialog_usuario: false,
      codigo_vincular: null,
      nombre_vincular: '',
      tipos_doc: [],
      localidades: [],
      coincidencias: [],
      headers: [
        { text: 'Codigo', value: 'codigo', sortable: false, align: 'end' },
        { text: 'Nombre', value: 'nombre', sortable: false },
        { text: 'Tipo Doc.', value: 'tipo_doc', sortable: false },
        { text: 'Nº Doc.', value: 'num_doc', sortable: false },
        { text: 'Cond. IVA', value: 'iva', sortable: false },
        { text: 'Domicilio', value: 'domicilio', sortable: false },
        { text: 'Telefono', value: 'telefono', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false, align: 'center' }
      ],
      selectedProvincia: {},
      gestor: {},
      rules: {
        required: value => !!value || 'Campo requerido'
      },
      credenciales: {
        user: '',
        pass: ''
      },
      headersEnt: [
        { text: 'Nombre', value: 'origen_nombre', sortable: false, align: 'center' },
        { text: 'Acciones', value: 'acciones', sortable: false, align: 'center' }
      ],
      entidades: [],
      loadEnt: false,
      searchEnt: '',
      entidadesDeposito: [],
      selectedEntidad: {},
      permiso: 0,
      tamInicial: 0
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('get_provincias')
    await this.$store.dispatch('get_tipos_doc')
      .then((data) => {
        this.tipos_doc = data.tipos_doc
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    // 09-02-2023 por MMURILLO, obtengo las entidades depósito disponibles para agregar
    let entidadesPeticion = await this.$store.dispatch('acreditaciones/getEntidadesDepositos')
    if (entidadesPeticion.resultado == 1){
      this.entidadesDeposito = entidadesPeticion.entidades
      this.permiso = entidadesPeticion.permiso
    }else{
      this.$store.dispatch('show_snackbar', {
        text: entidadesPeticion.msj,
        color: 'error'
      })
    }
    this.$store.state.loading = false
  },
  props: {
    value: Boolean,
    p_gestor: Object    // en caso de ser nuevo enviar null
  },
  computed: {
    ...mapState('gestores', ['p_editar']),
    ...mapState(['sucursales', 'cond_iva', 'ejecutivos', 'provincias']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    CrearUsuarioTmp
  },
  watch: {
    async dialog (val) {
      if (val) {
        if (this.p_gestor) {
          this.nuevo = false
          this.editar = false
          // crea una copia del prop p_gestor para editarlo
          this.gestor = JSON.parse(JSON.stringify(this.p_gestor))
          // obtiene las localidades
          this.selectedProvincia = {
            provincia_codigo: this.gestor.provincia_codigo,
            provincia_nombre: this.gestor.provincia_nombre
          }
          await new Promise(resolve => setTimeout(resolve, 1))
          await this.getLocalidades()
          // 09-02-2023 POR MMURILLO, OBTENGO LAS ENTIDADES DEPOSITO PARA EL CLIENTE SELECCIONADO
          await this.getEntidades()
        } else {
          this.nuevo = true
          this.editar = true
          this.nombre = null
          this.apellido = null
          this.localidades = []
          this.selectedProvincia = {}
          this.gestor = {}
        }
      } else {
        this.$refs.form.resetValidation()
        this.codigo_vincular = null
        this.coincidencias = []
        this.entidades = []
        this.selectedEntidad = {}
        this.tamInicial = 0
        this.credenciales = {
          user: '',
          pass: ''
        }
      }
    },
    async editar (val) {
      if (!val) {
        // vuelve a dejar los datos como estaban
        this.gestor = JSON.parse(JSON.stringify(this.p_gestor))
        this.selectedProvincia = {
          provincia_codigo: this.gestor.provincia_codigo,
          provincia_nombre: this.gestor.provincia_nombre
        }
        await this.getLocalidades()
        await this.getEntidades()
        this.credenciales = {
          user: '',
          pass: ''
        }
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {

        // valida que no guarde con un ejecutivo inhabilitado
        const ejecutivo = this.ejecutivos.find(ej => ej.codigo == this.gestor.ejecutivo_codigo)
        if (ejecutivo.inhabilitado == 1) {
          return this.$store.dispatch('show_snackbar', {
            text: 'El ejecutivo seleccionado se encuentra inhabilitado',
            color: 'orange'
          })
        }

        // valido q si tiene una entidad deposito 8 (cobro online) tenga usuario chi o chi
        let cobro_online = this.entidades.filter(ent => ent.origen_id == 8)
        if (cobro_online.length > 0 && (!this.gestor.usuario && !(this.credenciales.user && this.credenciales.pass))) {
          this.$store.dispatch('show_snackbar', {
            text: 'Para la entidad Cobro Online es necesario que el gestor tenga un usuario',
            color: 'orange'
          })
          return
        }

        this.load = true

        const response = await this.$store.dispatch('gestores/validar', {
          codigo: this.gestor.codigo,
          num_doc: this.gestor.num_doc
        })

        if (response.exito == 1) {
          if (response.existe == 1) {
            this.load = false
            return this.$store.dispatch('show_snackbar', {
              text: 'Ya existe un cliente mayorista con el mismo Nº de documento',
              color: 'error'
            })
          }
          else {
            const cantidad = response.cliente.length
            if (cantidad > 0 && !this.codigo_vincular) {
              this.load = false
              this.modal = true
              this.coincidencias = response.cliente
              return
            }
          }
        }
        else {
          this.load = false
          return this.$store.dispatch('show_snackbar', {
            text: response.message,
            color: 'error'
          })
        }

        let data = JSON.parse(JSON.stringify(this.gestor))
        data.cliente_existe = this.codigo_vincular
        data.entidades_deposito = this.entidades
        if (this.credenciales.user && this.credenciales.pass) {
          data.user = Encrypt(this.credenciales.user)
          data.pass = Encrypt(this.credenciales.pass)
        }

        if (this.nuevo) {
          data.nombre = this.nombre
          data.apellido = this.apellido
          await this.$store.dispatch('gestores/nuevo', data)
            .then((res) => {
              this.dialog = false
              this.gestor.nombre = this.apellido + ' ' + this.nombre
              // emite la accion definifa en el padre
              this.$emit('nuevo', this.gestor, res.codigos, res.usuario)
              // muestra mensaje
              this.$swal.fire({
                title: res.message,
                icon: 'success'
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
        } else {
          // para evitar el problema con los cambios, controlamos si hubo cambios en las entidades
          let huboCambios = 0
          // se agregaron nuevos?
          let nuevosReg = this.entidades.filter(element => parseInt(element.id) == 0)
          if (nuevosReg.length > 0) huboCambios = 1
          // el array tiene mas o menos registros que al principio?
          if (this.entidades.length != this.tamInicial) huboCambios = 1
          // solo edita si realmente cambio algun valor
          if ((JSON.stringify(this.gestor) != JSON.stringify(this.p_gestor)) || huboCambios == 1) {
            await this.$store.dispatch('gestores/editar', data)
              .then((res) => {
                this.dialog = false
                // obtiene los nombres de los datos que se muestran en el datatable
                this.gestor.ejecutivo_nombre = get_nombre_obj_arr(this.ejecutivos, 'codigo', this.gestor.ejecutivo_codigo, 'nombre')
                this.gestor.sucursal_nombre = get_nombre_obj_arr(this.sucursales, 'id', this.gestor.sucursal_codigo, 'nombre')
                this.gestor.cond_iva_nombre = get_nombre_obj_arr(this.cond_iva, 'condicion_iva_codigo', this.gestor.cond_iva_codigo, 'condicion_iva_nombre')
                // emite la accion definifa en el padre
                if (!this.gestor.usuario && res.usuario) {
                  this.gestor.usuario = res.usuario
                }
                this.$emit('editar', this.gestor)
                // muestra mensaje
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success'
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error'
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el cliente: los campos nuevos son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    },
    seleccionar (item) {
      this.modal = false
      this.codigo_vincular = item.codigo
      this.nombre_vincular = item.nombre
      // si alguno de los siguientes campos es nulo en el gestor, lo hereda de la coincidencia
      if (!this.gestor.domicilio) {
        this.gestor.domicilio = item.domicilio
      }
      if (!this.gestor.telefono) {
        this.gestor.telefono = item.telefono
      }
      if (!this.gestor.email) {
        this.gestor.email = item.email
      }
    },
    async getLocalidades () {
      this.localidades = []
      if (Object.keys(this.selectedProvincia).length > 0) {
        this.$store.state.loading = true
        let localidadesPeticion = await this.$store.dispatch('genericosAcc/get_localidades_x_provincia', { provincia_codigo: this.selectedProvincia.provincia_codigo })
        this.$store.state.loading = false
        if (localidadesPeticion.resultado == 1) {
          this.localidades = localidadesPeticion.localidades
        } else {
          this.$store.dispatch('show_snackbar', {
            text: localidadesPeticion.msj,
            color: 'error'
          })
        }
      }
    },
    async getEntidades () {
      this.$store.state.loading = true
      let entidadesPeticion = await this.$store.dispatch('acreditaciones/getOrigenesXCliente', { cliente_codigo: this.p_gestor.codigo })
      this.$store.state.loading = false

      if (entidadesPeticion.resultado == 1) {
        this.entidades = entidadesPeticion.origenes
        this.tamInicial = this.entidades.length
      } else {
        this.$store.dispatch('show_snackbar', {
          text: entidadesPeticion.msj,
          color: 'error'
        })
      }
    },
    addEntidadDeposito(){
      if (Object.keys(this.selectedEntidad).length > 0){
        // controlo si ya lo tengo agregado
        let existe = this.entidades.filter(element => element.origen_id == this.selectedEntidad.entidad_id)
        if (existe.length > 0){
          this.$store.dispatch('show_snackbar', {
            text: 'La entidad seleccionada ya existe en el Listado. No se puede agregar.',
            color: 'warning'
          })
          return
        }
        // paso a agregar
        let unaEntidad = {
          id: 0,
          circuito: 0,
          origen_id: this.selectedEntidad.entidad_id,
          origen_nombre: this.selectedEntidad.entidad_nombre
        }
        this.entidades.push(unaEntidad)
        this.selectedEntidad = {}
      }
    },
    quitarEntidad(item){
      let pos = this.entidades.indexOf(item)
      this.entidades.splice(pos, 1)
    },
    setUser (credentials) {
      this.credenciales.user = credentials.user
      this.credenciales.pass = credentials.pass
    }
  }
}
</script>