var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"300","persistent":_vm.load},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"disabled":_vm.load}},[_c('v-card-title',[_vm._v(" Modificar "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-3 pb-0 text-center body-1"},[_vm._v(" Límite de crédito del cliente "),_c('strong',[_vm._v(_vm._s(_vm.nombre))]),_c('v-text-field-money',{staticClass:"mx-8 pt-3",attrs:{"properties":{
          prefix: '$',
          type: 'tel',
          autofocus: true,
          outlined: true,
          dense: true
        },"options":{
          locale: 'es-AR',
          precision: 0,
          empty: 0
        }},model:{value:(_vm.limite),callback:function ($$v) {_vm.limite=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"limite"}})],1),_c('v-card-actions',{staticClass:"d-flex justify-end pb-4"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","disabled":_vm.load},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('BtnConfirmar',{attrs:{"disabled":!_vm.codigo,"loading":_vm.load},on:{"action":function($event){return _vm.guardar()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }